<template>
  <div>
    <div class="row border-bottom align-items-center z-100">
      <label class="col-12 col-md-2 font-weight-bold py-2 mb-2 text-md-right"
        >Activity Category</label
      >
      <alert
        v-if="activityCategoriesLoading"
        class="light-shadow mt-2 col-12 col-md-6"
        style="padding-top: 7.5px; padding-bottom: 7.5px"
      ></alert>
      <div class="form-group class-ddl mt-2 pt-1 col-12 col-md-6" v-else>
        <multiselect
          class="multiselect"
          v-model="filter.activityCategories"
          :options="activityCategories"
          @input="filterIsDirty = true"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="All Activity Categories"
          Value="id"
          label="name"
          track-by="id"
          :preselect-first="false"
        >
        </multiselect>
      </div>
    </div>
    <div class="row border-bottom align-items-center z-100">
      <label class="col-12 col-md-2 font-weight-bold py-2 mb-2 text-md-right"
        >Date</label
      >
      <div class="mt-2 pt-1 col-12 col-md-6">
        <v-date-picker
          v-model="range"
          mode="date"
          :masks="masks"
          is-range
          @input="filterIsDirty = true"
        >
          <template v-slot="{ inputValue, inputEvents, isDragging }">
            <div
              class="
                d-flex
                justify-content-start
                align-items-center
                form-group
                mb-4
              "
            >
              <input
                class="px-2 py-1 form-control light-shadow"
                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                :value="inputValue.start"
                v-on="inputEvents.start"
              />
              <div class="mx-2 mx-md-4">-</div>
              <input
                class="px-2 py-1 form-control light-shadow"
                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                :value="inputValue.end"
                v-on="inputEvents.end"
              />
            </div>
          </template>
        </v-date-picker>
      </div>
    </div>
    <div
      class="col-11 d-flex flex-wrap mt-2 py-4 row mx-0 px-0 ml-md-6 ml-lg-7"
    >
      <div class="custom-control custom-checkbox mr-md-5">
        <input
          type="checkbox"
          class="custom-control-input"
          id="show_provider_observation"
          v-model="showProviderObservation"
          :disabled="
            !(getCleanedQuestionnaires && getCleanedQuestionnaires.length > 0)
          "
          @click="toggleProviderObservation"
        />
        <label
          class="custom-control-label mr-3 mb-3"
          for="show_provider_observation"
          >Show Provider Observation</label
        >
      </div>
      <div class="custom-control custom-checkbox mr-md-5">
        <input
          type="checkbox"
          class="custom-control-input"
          id="show_client_answers"
          v-model="showClientAnswers"
          :disabled="
            !(getCleanedQuestionnaires && getCleanedQuestionnaires.length > 0)
          "
          @click="toggleClientAnswers"
        />
        <label class="custom-control-label mr-3 mb-3" for="show_client_answers"
          >Show Client Answers</label
        >
      </div>
    </div>
    <div
      class="
        my-4
        d-flex
        justify-content-between
        flex-wrap
        report-actions-container
      "
      v-if="!loading"
    >
      <button
        class="
          mr-3
          mt-3
          btn btn-black
          text-uppercase
          view-report
          position-relative
        "
        :saving="loading"
        @click="getReport"
      >
        View Report
        <div
          class="action-indicator btn btn-danger spinner-grow"
          v-if="filterIsDirty"
        ></div>
      </button>
      <div class="d-flex flex-wrap">
        <div class="d-flex mt-3 mr-3">
          <button
            :disabled="
              !(getCleanedQuestionnaires && getCleanedQuestionnaires.length > 0)
            "
            class="btn btn-theme text-uppercase"
            @click="handlePrintButton"
          >
            <i class="fas fa-print fa-lg"></i> Print
          </button>
          <div class="btn-group">
            <button
              type="button"
              class="btn p-1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :disabled="
                !(
                  getCleanedQuestionnaires &&
                  getCleanedQuestionnaires.length > 0
                )
              "
            >
              <i class="fas fa-cog"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right print-menu"
              @click="$event.stopPropagation()"
            >
              <h3 class="mx-3 my-2">
                <i class="fas fa-cog mr-2"></i>Print settings
              </h3>
              <div class="dropdown-divider"></div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_second_logo_in_report"
                    v-model="printSettings.show_second_logo_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_second_logo_in_report"
                    >Second Logo</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_name_in_report"
                    v-model="printSettings.show_name_in_report"
                  />
                  <label class="custom-control-label" for="show_name_in_report"
                    >Name</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_email_in_report"
                    v-model="printSettings.show_email_in_report"
                  />
                  <label class="custom-control-label" for="show_email_in_report"
                    >Email</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_phone_number_in_report"
                    v-model="printSettings.show_phone_number_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_phone_number_in_report"
                    >Phone Number</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_claim_number_in_report"
                    v-model="printSettings.show_claim_number_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_claim_number_in_report"
                    >Claim Number</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_date_of_loss_in_report"
                    v-model="printSettings.show_date_of_loss_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_date_of_loss_in_report"
                    >Date of Loss</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_date_of_birth_in_report"
                    v-model="printSettings.show_date_of_birth_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_date_of_birth_in_report"
                    >Date of Birth</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_show_report_period"
                    v-model="printSettings.show_report_period"
                  />
                  <label
                    class="custom-control-label"
                    for="show_show_report_period"
                    >Report Period</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mt-3 mr-3" v-if="false">
          <span v-tooltip.top="'Coming soon...'">
            <button
              :disabled="
                !(
                  getCleanedQuestionnaires &&
                  getCleanedQuestionnaires.length > 0
                ) || true
              "
              class="btn btn-danger"
            >
              <i class="fas fa-lg fa-file-pdf"></i> SAVE PDF
            </button>
          </span>
          <div class="btn-group">
            <button
              type="button"
              :disabled="
                !(
                  getCleanedQuestionnaires &&
                  getCleanedQuestionnaires.length > 0
                ) || true
              "
              class="btn p-1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-cog"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right print-menu"
              @click="$event.stopPropagation()"
            >
              <h3 class="mx-3 my-2">
                <i class="fas fa-cog mr-2"></i>Save PDF settings
              </h3>
              <div class="dropdown-divider"></div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_second_logo_in_report_pdf"
                    v-model="pdfSetting.show_second_logo_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_second_logo_in_report_pdf"
                    >Second Logo</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_name_in_report_pdf"
                    v-model="pdfSetting.show_name_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_name_in_report_pdf"
                    >Name</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_email_in_report_pdf"
                    v-model="pdfSetting.show_email_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_email_in_report_pdf"
                    >Email</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_phone_number_in_report_pdf"
                    v-model="pdfSetting.show_phone_number_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_phone_number_in_report_pdf"
                    >Phone Number</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_claim_number_in_report_pdf"
                    v-model="pdfSetting.show_claim_number_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_claim_number_in_report_pdf"
                    >Claim Number</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_date_of_loss_in_report_pdf"
                    v-model="pdfSetting.show_date_of_loss_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_date_of_loss_in_report_pdf"
                    >Date of Loss</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_date_of_birth_in_report_pdf"
                    v-model="pdfSetting.show_date_of_birth_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_date_of_birth_in_report_pdf"
                    >Date of Birth</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_show_report_period_pdf"
                    v-model="pdfSetting.show_report_period"
                  />
                  <label
                    class="custom-control-label"
                    for="show_show_report_period_pdf"
                    >Report Period</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <alert v-if="loading" class="mt-5" />
    <div v-show="showCharts && !loading" class="container charts p-0">
      <div
        class="
          chartContainer
          dashboard-item-container
          light-shadow
          semi-transparent-card
        "
      >
        <div class="row m-0 justify-content-between dashboard-item-header">
          <div class="dashboard-item-title">Pain Chart</div>
          <div class="text-muted small">0 = No Pain &nbsp; 10 = High Pain</div>
        </div>
        <div class="d-flex justify-content-center w-100 mb-3">
          <div
            class="d-flex flex-column small border rounded px-3 mb-2 ml-auto"
          >
            <div class="font-weight-bold text-center border-bottom mb-2">
              Average Pain
            </div>
            <div>
              Client Answer:
              <b class="text-danger"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                  averagePain.client || "0"
                }}</b
              >
              <span v-if="painLevels[averagePain.client]">
                ({{ painLevels[averagePain.client] }})
              </span>
            </div>
            <div>
              Provider observation:
              <b class="text-danger"
                >&nbsp;&nbsp;&nbsp;{{ averagePain.provider || "0" }}</b
              >
              <span v-if="painLevels[averagePain.provider]">
                ({{ painLevels[averagePain.provider] }})</span
              >
            </div>
          </div>
        </div>
        <div id="painChart"></div>
        <img
          src="@/assets/img/pain-scale.png"
          alt="Pain Scale"
          class="col-12 col-lg-8 offset-lg-2"
        />
      </div>
      <div
        class="
          chartContainer
          dashboard-item-container
          light-shadow
          semi-transparent-card
        "
      >
        <div class="row m-0 justify-content-between dashboard-item-header">
          <div class="dashboard-item-title">Fatigue Chart</div>
          <div class="text-muted small">
            0 = No Fatigue &nbsp; 10 = Exhausted
          </div>
        </div>
        <div class="d-flex justify-content-center w-100 mb-3">
          <div
            class="d-flex flex-column small border rounded px-3 mb-2 ml-auto"
          >
            <div class="font-weight-bold text-center border-bottom mb-2">
              Average Fatigue
            </div>
            <div>
              Client Answer:
              <b class="text-danger"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                  averageFatigue.client || "0"
                }}</b
              >
              <span v-if="fatigueLevels[averageFatigue.client]">
                ({{ fatigueLevels[averageFatigue.client] }})</span
              >
            </div>
            <div>
              Provider observation:
              <b class="text-danger"
                >&nbsp;&nbsp;&nbsp;{{ averageFatigue.provider || "0" }}</b
              >
              <span v-if="fatigueLevels[averageFatigue.provider]">
                ({{ fatigueLevels[averageFatigue.provider] }})</span
              >
            </div>
          </div>
        </div>
        <div id="fatigueChart"></div>
        <img
          src="@/assets/img/fatigue-scale.png"
          alt="Fatigue Scale"
          class="col-12 col-lg-8 offset-lg-2"
        />
      </div>
      <div
        class="
          chartContainer
          dashboard-item-container
          light-shadow
          semi-transparent-card
        "
      >
        <div class="row m-0 justify-content-between dashboard-item-header mb-3">
          <div class="dashboard-item-title">Sleep Chart</div>
          <button
            class="btn btn-sm btn-secondary pb-0"
            v-if="!loading"
            @click="changeChartType('sleepChart')"
          >
            {{ chartHorizontal.sleepChart ? "Horizontal" : "Vertical" }}
            <i
              :class="`ml-2 fas ${
                chartHorizontal.sleepChart
                  ? 'fa-grip-horizontal'
                  : 'fa-grip-vertical'
              }`"
            ></i>
          </button>
        </div>
        <div id="sleepChart"></div>
      </div>
      <div
        class="
          chartContainer
          dashboard-item-container
          light-shadow
          semi-transparent-card
        "
      >
        <div class="row m-0 justify-content-between dashboard-item-header">
          <div class="dashboard-item-title">Mood Chart</div>
          <div class="text-muted small">0 = Low &nbsp; 10 = Wonderful</div>
        </div>
        <div class="d-flex justify-content-center w-100 mb-3">
          <div
            class="d-flex flex-column small border rounded px-3 mb-2 ml-auto"
          >
            <div class="font-weight-bold text-center border-bottom mb-2">
              Average Mood
            </div>
            <div>
              Client Answer:
              <b class="text-danger"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                  averageMood.client || "0"
                }}</b
              >
              <span v-if="moodLevels[averageMood.client]">
                ({{ moodLevels[averageMood.client] }})</span
              >
            </div>
            <div>
              Provider observation:
              <b class="text-danger"
                >&nbsp;&nbsp;&nbsp;{{ averageMood.provider || "0" }}</b
              >
              <span v-if="moodLevels[averageMood.provider]">
                ({{ moodLevels[averageMood.provider] }})</span
              >
            </div>
          </div>
        </div>
        <div id="moodChart"></div>
        <img
          src="@/assets/img/mood-scale.png"
          alt="Mood Scale"
          class="col-12 col-lg-8 offset-lg-2"
        />
      </div>
      <div
        class="
          chartContainer
          dashboard-item-container
          light-shadow
          semi-transparent-card
        "
      >
        <div class="row m-0 justify-content-between dashboard-item-header mb-3">
          <div class="dashboard-item-title">Anxiety Chart</div>
        </div>
        <div class="chart-img text-center" v-html="biChartSVGContent"></div>
        <div class="monthly-details">
          <div class="row mx-0 justify-content-between">
            <div class="d-flex align-items-center">
              <div class="mr-2 info-devider secondary-info"></div>
              <div class="info-text">
                Non anxiety:
                <span class="value">
                  {{ anxietyData.noAnxietyPercent.toFixed(2) }}%
                </span>
              </div>
            </div>
            <div class="secondary-info info-tip radius-99">
              {{ anxietyData.noAnxietyCount }}
            </div>
          </div>
          <div class="row mx-0 justify-content-between mt-1">
            <div class="d-flex align-items-center">
              <div class="mr-2 info-devider main-info"></div>
              <div class="info-text">
                Anxiety:
                <span class="value">
                  {{ anxietyData.anxietyPercent.toFixed(2) }}%
                </span>
              </div>
            </div>
            <div class="main-info info-tip radius-99">
              {{ anxietyData.anxietyCount }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          chartContainer
          dashboard-item-container
          light-shadow
          semi-transparent-card
        "
      >
        <div class="row m-0 justify-content-between dashboard-item-header mb-3">
          <div class="dashboard-item-title">Headaches Chart</div>
          <div class="small">
            Average Headache: <b class="text-danger">{{ averageHeadache }}</b>
            <span v-if="headacheLevels[averageHeadache]">
              ({{ headacheLevels[averageHeadache] }})</span
            >
          </div>
          <div class="text-muted small">0 = No Headache &nbsp; 10 = High</div>
        </div>
        <div id="headachesChart"></div>
      </div>
      <div
        class="
          chartContainer
          dashboard-item-container
          light-shadow
          semi-transparent-card
        "
      >
        <div class="row m-0 justify-content-between dashboard-item-header mb-3">
          <div class="dashboard-item-title">
            General Symptoms Observed Chart
          </div>
          <button
            class="btn btn-sm btn-secondary pb-0"
            v-if="!loading"
            @click="changeChartType('generalSymptomsObservedChart')"
          >
            {{
              chartHorizontal.generalSymptomsObservedChart
                ? "Horizontal"
                : "Vertical"
            }}
            <i
              :class="`ml-2 fas ${
                chartHorizontal.generalSymptomsObservedChart
                  ? 'fa-grip-horizontal'
                  : 'fa-grip-vertical'
              }`"
            ></i>
          </button>
        </div>
        <div id="generalSymptomsObservedChart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import dateFormat from "@/utils/dateFormat";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import ApexCharts from "apexcharts";
import CONSTANT from "./constants";
import QUESTIONS from "../../../../store/patients/constants";
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      range: {
        start: new Date(),
        end: new Date(),
      },
      masks: {
        input: "YYYY-MM-DD",
      },
      filterIsDirty: true,
      filter: {
        activityCategories: [],
      },
      averagePain: {
        client: "N/A",
        provider: "N/A",
      },
      averageFatigue: {
        client: "N/A",
        provider: "N/A",
      },
      averageMood: {
        client: "N/A",
        provider: "N/A",
      },
      averageHeadache: "N/A",
      painLevels: CONSTANT.PAIN_LEVELS,
      fatigueLevels: CONSTANT.FATIGUE_LEVELS,
      moodLevels: CONSTANT.MOOD_LEVELS,
      headacheLevels: CONSTANT.HEADACHE_LEVELS,
      generalSymptomsObservedData: [],
      generalSymptomsObservedCategories: QUESTIONS.GSO_QUESTIONS,
      showProviderObservation: true,
      showClientAnswers: true,
      biChartSVGContent: "",
      printSettings: {
        show_name_in_report: true,
        show_email_in_report: true,
        show_phone_number_in_report: true,
        show_claim_number_in_report: true,
        show_date_of_loss_in_report: true,
        show_date_of_birth_in_report: true,
        show_report_period: true,
      },
      pdfSetting: {
        show_name_in_report: true,
        show_email_in_report: true,
        show_phone_number_in_report: true,
        show_claim_number_in_report: true,
        show_date_of_loss_in_report: true,
        show_date_of_birth_in_report: true,
        show_report_period: true,
      },
      showCharts: false,
      chartsRef: {
        painChart: null,
        fatigueChart: null,
        sleepChart: null,
        generalSymptomsObservedChart: null,
        moodChart: null,
        headachesChart: null,
      },
      chartHorizontal: {
        sleepChart: false,
        generalSymptomsObservedChart: false,
      },
      painChart: CONSTANT.painLineChart,
      fatigueChart: CONSTANT.fatigueLineChart,
      moodChart: CONSTANT.moodLineChart,
      sleepChart: CONSTANT.sleepBarChart,
      sleepChartHorizontal: CONSTANT.sleepBarChartHorizontal,
      generalSymptomsObservedChart: CONSTANT.generalSymptomsObservedBarChart,
      generalSymptomsObservedChartHorizontal:
        CONSTANT.generalSymptomsObservedBarChartHorizontal,
      headachesChart: CONSTANT.headachesAreaChart,
    };
  },
  mounted() {
    fetch("/images/bi-chart.svg")
      .then((response) => response.text())
      .then((data) => {
        this.biChartSVGContent = data;
      })
      .catch((error) => console.error(error));
    this.setQuestionnairesForReport([]);
    this.getActivityCategories();
  },
  beforeDestroy() {
    this.chartsRef.painChart && this.chartsRef.painChart.destroy();
    this.chartsRef.fatigueChart && this.chartsRef.fatigueChart.destroy();
    this.chartsRef.sleepChart && this.chartsRef.sleepChart.destroy();
    this.chartsRef.moodChart && this.chartsRef.moodChart.destroy();
    this.chartsRef.headachesChart && this.chartsRef.headachesChart.destroy();
    this.chartsRef.generalSymptomsObservedChart &&
      this.chartsRef.generalSymptomsObservedChart.destroy();
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.patients.patient,
      questionnaires: (state) =>
        state.patients.symptoms.questionnairesForReport.data,
      loading: (state) =>
        state.patients.symptoms.questionnairesForReport.loading,
      mapParts: (state) => state.patients.symptoms.mapParts,
      painTime: (state) => state.patients.symptoms.painTime,
      activityCategories: (state) =>
        state.patients.symptoms.activityCategories.data,
      activityCategoriesLoading: (state) =>
        state.patients.symptoms.activityCategories.loading,
      GSOQuestions: (state) => state.patients.symptoms.GSOQuestions,
    }),
    getCleanedQuestionnaires: function () {
      return this.questionnaires;
      // .filter((item) => {
      //   return item.body_locations && item.body_locations.length > 0;
      // });
    },
    anxietyData: function () {
      let numberOfAnxiety = 0;
      let numberOfNoAnxiety = 0;
      for (let questionnaire of this.questionnaires) {
        if (questionnaire.anxiety_client) {
          if (questionnaire.anxiety_client === 1) {
            numberOfAnxiety++;
          } else {
            numberOfNoAnxiety++;
          }
        }
      }
      const total = numberOfAnxiety + numberOfNoAnxiety;
      if (total === 0) {
        return {
          anxietyCount: 0,
          noAnxietyCount: 0,
          noAnxietyPercent: 0,
          anxietyPercent: 0,
        };
      }
      return {
        anxietyCount: numberOfAnxiety,
        noAnxietyCount: numberOfNoAnxiety,
        noAnxietyPercent: (numberOfNoAnxiety / total) * 100,
        anxietyPercent: (numberOfAnxiety / total) * 100,
      };
    },
  },
  methods: {
    ...mapActions({
      getQuestionnaires: "patients/symptoms/getQuestionnairesByPeriod",
      setQuestionnairesForReport:
        "patients/symptoms/setQuestionnairesForReport",
      getActivityCategories: "patients/symptoms/getActivityCategories",
    }),
    getReport: function () {
      if (!this.filterIsDirty) {
        return;
      }
      const vm = this;
      this.filterIsDirty = false;
      if (
        this.range.start != null &&
        this.range.end != null &&
        this.currentPatient.user_id != null
      ) {
        const payload = {};
        const startDateTime = `${dateFormat(
          this.range.start,
          "YYYY-MM-DD"
        )}T00:00:00.000000Z`;
        const endDateTime = `${dateFormat(
          this.range.end,
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;
        const canadaOffsetStart = dayjs
          .tz(new Date(startDateTime), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(endDateTime), "America/Toronto")
          .utcOffset();
        payload.start = dayjs(startDateTime)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();
        payload.end = dayjs(endDateTime)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();
        payload.id = this.currentPatient.user_id;
        if (this.filter.activityCategories.length) {
          payload.activity_categories = `[${this.filter.activityCategories
            .map((item) => item.id)
            .join(",")}]`;
        }
        this.getQuestionnaires(payload).then(() => {
          vm.showCharts = true;
          vm.getPainChart();
          vm.getFatigueChart();
          vm.getSleepChart();
          vm.getMoodChart();
          vm.getAnxietyChart();
          vm.getHeadachesChart();
          vm.getGeneralSymptomsObservedChart();
        });
      }
    },
    getPainChart: function () {
      // eslint-disable-next-line no-undef
      const options = _.cloneDeep(this.painChart);
      const horizontalSeries = [[], []];
      const horizontalCategories = [];
      let averageClientPain = 0;
      let averageProviderPain = 0;
      let clientTotal = 0.0;
      let providerTotal = 0.0;
      for (let questionnaire of this.questionnaires) {
        if (
          questionnaire.body_locations &&
          questionnaire.body_locations.length > 0
        ) {
          if (questionnaire.pain_rate_client != -1) {
            horizontalSeries[0].push({
              y: Number(questionnaire.pain_rate_client),
              x: `${this.formattedDate(
                questionnaire.apt_start_time
              )} - ${this.formattedDate(questionnaire.apt_end_time)}`,
              u: {
                body_locations: this.getBodyLocations(
                  questionnaire.body_locations
                ),
                pain_time: this.painTime[questionnaire.time_pain_client],
                activity_categories: this.getActivityCategoriesNames(
                  questionnaire.activity_categories
                ),
              },
            });
            averageClientPain += Number(questionnaire.pain_rate_client);
            clientTotal++;
          }

          horizontalSeries[1].push({
            y: Number(questionnaire.pain_rate_provider),
            x: `${this.formattedDate(
              questionnaire.apt_start_time
            )} - ${this.formattedDate(questionnaire.apt_end_time)}`,
            u: {
              body_locations: this.getBodyLocations(
                questionnaire.body_locations
              ),
              pain_time: this.painTime[questionnaire.time_pain_provider],
              activity_categories: this.getActivityCategoriesNames(
                questionnaire.activity_categories
              ),
            },
          });
          horizontalCategories.push(
            this.formattedDate(questionnaire.apt_start_time, true)
          );
          averageProviderPain += Number(questionnaire.pain_rate_provider);
          providerTotal++;
        }
      }
      this.averagePain.client = clientTotal
        ? (averageClientPain / clientTotal).toFixed(0)
        : "N/A";
      this.averagePain.provider = providerTotal
        ? (averageProviderPain / providerTotal).toFixed(0)
        : "N/A";
      options.series[0].data = horizontalSeries[0];
      options.series[1].data = horizontalSeries[1];
      options.xaxis.categories = horizontalCategories;
      if (!this.chartsRef.painChart) {
        this.chartsRef.painChart = new ApexCharts(
          document.querySelector("#painChart"),
          options
        );
        this.chartsRef.painChart.render();
      } else {
        this.chartsRef.painChart.updateOptions(options);
      }
    },
    getFatigueChart: function () {
      // eslint-disable-next-line no-undef
      const options = _.cloneDeep(this.fatigueChart);
      const horizontalSeries = [[], []];
      const horizontalCategories = [];
      let averageClientFatigue = 0;
      let averageProviderFatigue = 0;
      let clientTotal = 0.0;
      let providerTotal = 0.0;
      for (let questionnaire of this.questionnaires) {
        if (
          (questionnaire.fatigue_rate_client ||
            questionnaire.fatigue_rate_client === 0) &&
          (questionnaire.fatigue_rate_provider ||
            questionnaire.fatigue_rate_provider === 0)
        ) {
          if (questionnaire.fatigue_rate_client != -1) {
            horizontalSeries[0].push({
              y: Number(questionnaire.fatigue_rate_client),
              x: `${this.formattedDate(
                questionnaire.apt_start_time
              )} - ${this.formattedDate(questionnaire.apt_end_time)}`,
              u: {
                activity_categories: this.getActivityCategoriesNames(
                  questionnaire.activity_categories
                ),
              },
            });
            averageClientFatigue += Number(questionnaire.fatigue_rate_client);
            clientTotal++;
          }
          horizontalSeries[1].push({
            y: Number(questionnaire.fatigue_rate_provider),
            x: `${this.formattedDate(
              questionnaire.apt_start_time
            )} - ${this.formattedDate(questionnaire.apt_end_time)}`,
            u: {
              activity_categories: this.getActivityCategoriesNames(
                questionnaire.activity_categories
              ),
            },
          });
          horizontalCategories.push(
            this.formattedDate(questionnaire.apt_start_time, true)
          );
          averageProviderFatigue += Number(questionnaire.fatigue_rate_provider);
          providerTotal++;
        }
      }
      this.averageFatigue.client = clientTotal
        ? (averageClientFatigue / clientTotal).toFixed(0)
        : "N/A";
      this.averageFatigue.provider = providerTotal
        ? (averageProviderFatigue / providerTotal).toFixed(0)
        : "N/A";

      options.series[0].data = horizontalSeries[0];
      options.series[1].data = horizontalSeries[1];
      options.xaxis.categories = horizontalCategories;
      if (!this.chartsRef.fatigueChart) {
        this.chartsRef.fatigueChart = new ApexCharts(
          document.querySelector("#fatigueChart"),
          options
        );
        this.chartsRef.fatigueChart.render();
      } else {
        this.chartsRef.fatigueChart.updateOptions(options);
      }
    },
    getSleepChart: function () {
      if (this.chartsRef.sleepChart) this.chartsRef.sleepChart.destroy();
      const options = this.sleepChart;
      const horizontalOptions = this.sleepChartHorizontal;
      const horizontalSeries = [];
      const horizontalCategories = [];
      for (let questionnaire of this.questionnaires) {
        if (questionnaire.hours_client || questionnaire.hours_client === 0) {
          horizontalSeries.push({
            y: Number(questionnaire.hours_client),
            x: `${this.formattedDate(
              questionnaire.apt_start_time
            )} - ${this.formattedDate(questionnaire.apt_end_time)}`,
            u: {
              activity_categories: this.getActivityCategoriesNames(
                questionnaire.activity_categories
              ),
            },
          });
          horizontalCategories.push(
            this.formattedDate(questionnaire.apt_start_time, true)
          );
        }
      }

      options.series[0].data = horizontalSeries;
      options.xaxis.categories = horizontalCategories;
      horizontalOptions.series[0].data = horizontalSeries;
      horizontalOptions.yaxis.categories = horizontalCategories;

      if (!this.chartHorizontal.sleepChart) {
        this.chartsRef.sleepChart = new ApexCharts(
          document.querySelector("#sleepChart"),
          options
        );
        this.chartsRef.sleepChart.render();
      } else {
        horizontalOptions.chart.height =
          horizontalCategories.length < 4
            ? 200
            : horizontalCategories.length * 50;
        this.chartsRef.sleepChart = new ApexCharts(
          document.querySelector("#sleepChart"),
          horizontalOptions
        );
        this.chartsRef.sleepChart.render();
      }
    },
    getMoodChart: function () {
      // eslint-disable-next-line no-undef
      const options = _.cloneDeep(this.moodChart);
      const horizontalSeries = [[], []];
      const horizontalCategories = [];
      let averageClientMood = 0;
      let averageProviderMood = 0;
      let clientTotal = 0.0;
      let providerTotal = 0.0;
      for (let questionnaire of this.questionnaires) {
        if (
          (questionnaire.mood_rate_client ||
            questionnaire.mood_rate_client === 0) &&
          (questionnaire.mood_rate_provider ||
            questionnaire.mood_rate_provider === 0)
        ) {
          if (questionnaire.mood_rate_client != -1) {
            horizontalSeries[0].push({
              y: Number(questionnaire.mood_rate_client),
              x: `${this.formattedDate(
                questionnaire.apt_start_time
              )} - ${this.formattedDate(questionnaire.apt_end_time)}`,
              u: {
                activity_categories: this.getActivityCategoriesNames(
                  questionnaire.activity_categories
                ),
              },
            });
            averageClientMood += Number(questionnaire.mood_rate_client);
            clientTotal++;
          }
          horizontalSeries[1].push({
            y: Number(questionnaire.mood_rate_provider),
            x: `${this.formattedDate(
              questionnaire.apt_start_time
            )} - ${this.formattedDate(questionnaire.apt_end_time)}`,
            u: {
              activity_categories: this.getActivityCategoriesNames(
                questionnaire.activity_categories
              ),
            },
          });
          horizontalCategories.push(
            this.formattedDate(questionnaire.apt_start_time, true)
          );
          averageProviderMood += Number(questionnaire.mood_rate_provider);
          providerTotal++;
        }
      }
      this.averageMood.client = clientTotal
        ? (averageClientMood / clientTotal).toFixed(0)
        : "N/A";
      this.averageMood.provider = providerTotal
        ? (averageProviderMood / providerTotal).toFixed(0)
        : "N/A";
      options.series[0].data = horizontalSeries[0];
      options.series[1].data = horizontalSeries[1];
      options.xaxis.categories = horizontalCategories;
      if (!this.chartsRef.moodChart) {
        this.chartsRef.moodChart = new ApexCharts(
          document.querySelector("#moodChart"),
          options
        );
        this.chartsRef.moodChart.render();
      } else {
        this.chartsRef.moodChart.updateOptions(options);
      }
    },
    getAnxietyChart: function () {
      this.calculateBiChartPercentage(this.anxietyData.noAnxietyPercent);
    },
    calculateBiChartPercentage: function (percentage) {
      const radius = 72;
      const centerX = 89;
      const centerY = 89;
      if (percentage == 100) {
        percentage = 99.99999;
      }

      const endAngle = (percentage / 100) * 2 * Math.PI;
      const largeArcFlag = percentage > 50 ? 1 : 0;

      const x = centerX + radius * Math.cos(endAngle - Math.PI / 2);
      const y = centerY + radius * Math.sin(endAngle - Math.PI / 2);

      const d = `M ${centerX},${
        centerY - radius
      } A ${radius},${radius} 0 ${largeArcFlag} 1 ${x},${y} L ${centerX},${centerY} Z`;
      if (document.getElementById("monthlyArcPath")) {
        document.getElementById("monthlyArcPath").setAttribute("d", d);
      }
    },
    getHeadachesChart: function () {
      // eslint-disable-next-line no-undef
      const options = _.cloneDeep(this.headachesChart);
      const horizontalSeries = [];
      const horizontalCategories = [];
      let averageClientHeadache = 0;
      let total = 0.0;
      for (let questionnaire of this.questionnaires) {
        if (
          questionnaire.headaches_rate_client != -1 &&
          (questionnaire.headaches_rate_client ||
            questionnaire.headaches_rate_client === 0)
        ) {
          horizontalSeries.push({
            y: Number(questionnaire.headaches_rate_client),
            x: `${this.formattedDate(
              questionnaire.apt_start_time
            )} - ${this.formattedDate(questionnaire.apt_end_time)}`,
            u: {
              activity_categories: this.getActivityCategoriesNames(
                questionnaire.activity_categories
              ),
            },
          });
          horizontalCategories.push(
            this.formattedDate(questionnaire.apt_start_time, true)
          );
          averageClientHeadache += Number(questionnaire.headaches_rate_client);
          total++;
        }
      }
      this.averageHeadache = total
        ? (averageClientHeadache / total).toFixed(0)
        : "N/A";
      options.series[0].data = horizontalSeries;
      options.xaxis.categories = horizontalCategories;
      if (!this.chartsRef.headachesChart) {
        this.chartsRef.headachesChart = new ApexCharts(
          document.querySelector("#headachesChart"),
          options
        );
        this.chartsRef.headachesChart.render();
      } else {
        this.chartsRef.headachesChart.updateOptions(options);
      }
    },
    getGeneralSymptomsObservedChart: function () {
      if (this.chartsRef.generalSymptomsObservedChart)
        this.chartsRef.generalSymptomsObservedChart.destroy();
      const options = this.generalSymptomsObservedChart;
      const horizontalOptions = this.generalSymptomsObservedChartHorizontal;
      const horizontalSeries = [];
      const horizontalCategories = this.generalSymptomsObservedCategories;
      const questionnaires = this.questionnaires;
      if (questionnaires.length) {
        this.GSOQuestions.forEach((category, index) => {
          const yValue = this.getPercentFor(category.key, questionnaires);
          if (yValue) {
            horizontalSeries.push({
              y: yValue,
              x: horizontalCategories[index].keyword,
              label: horizontalCategories[index].text,
            });
          }
        });
      }
      this.generalSymptomsObservedData = [...horizontalSeries];
      options.series[0].data = horizontalSeries;
      options.xaxis.categories = horizontalCategories;
      horizontalOptions.series[0].data = horizontalSeries;
      horizontalOptions.yaxis.categories = horizontalCategories;

      if (!this.chartHorizontal.generalSymptomsObservedChart) {
        this.chartsRef.generalSymptomsObservedChart = new ApexCharts(
          document.querySelector("#generalSymptomsObservedChart"),
          options
        );
        this.chartsRef.generalSymptomsObservedChart.render();
      } else {
        horizontalOptions.chart.height =
          horizontalCategories.length < 4
            ? 200
            : horizontalCategories.length * 50;
        this.chartsRef.generalSymptomsObservedChart = new ApexCharts(
          document.querySelector("#generalSymptomsObservedChart"),
          horizontalOptions
        );
        this.chartsRef.generalSymptomsObservedChart.render();
      }
    },
    handlePrintButton: function () {
      if (
        this.getCleanedQuestionnaires &&
        this.getCleanedQuestionnaires.length > 0
      ) {
        window.localStorage.setItem(
          "questionnaires",
          JSON.stringify(this.getCleanedQuestionnaires)
        );

        window.localStorage.setItem(
          "generalSymptomsObservedData",
          JSON.stringify(this.generalSymptomsObservedData)
        );
        const vm = this;
        this.getAllChartsURI().then(() => {
          const routeData = vm.$router.resolve({
            name: "patients.reports.print",
            query: {
              printSettingsProp: vm.printSettings,
              range: vm.range,
              savePDF: "false",
            },
          });

          window.open(
            routeData.href,
            "targetWindow",
            `toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, fullscreen=yes`
          );
        });
      }
    },
    handleSavePDFButton: function () {
      if (
        this.getCleanedQuestionnaires &&
        this.getCleanedQuestionnaires.length > 0
      ) {
        window.localStorage.setItem(
          "questionnaires",
          JSON.stringify(this.getCleanedQuestionnaires)
        );
        const vm = this;
        this.getAllChartsURI().then(() => {
          const routeData = vm.$router.resolve({
            name: "patients.reports.print",
            query: {
              printSettingsProp: vm.pdfSetting,
              range: vm.range,
              savePDF: "true",
            },
          });

          window.open(
            routeData.href,
            `toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, width=500, height=500`
          );
        });
      }
    },
    getAllChartsURI: function () {
      const chartsName = [];
      let chartsURIPromises = [];
      for (let chart in this.chartsRef) {
        if (this.chartsRef[chart]) {
          chartsName.push(chart);
          chartsURIPromises.push(this.chartsRef[chart].dataURI());
        }
      }
      return Promise.all(chartsURIPromises).then((res) => {
        const chartsURI = {};
        res.map((chart, index) => {
          chartsURI[chartsName[index]] = chart.imgURI;
        });
        window.localStorage.setItem("chartURI", JSON.stringify(chartsURI));

        const average = {
          pain: this.averagePain,
          fatigue: this.averageFatigue,
          mood: this.averageMood,
          headache: this.averageHeadache,
        };
        window.localStorage.setItem("average", JSON.stringify(average));
      });
    },
    changeChartType: function (chartSelector) {
      this.chartHorizontal[chartSelector] =
        !this.chartHorizontal[chartSelector];
      this.chartsRef[chartSelector].destroy();
      const chartMap = {
        sleepChart: this.getSleepChart(),
        generalSymptomsObservedChart: this.getGeneralSymptomsObservedChart(),
      };
      chartMap[chartMap];
    },
    getBodyLocations: function (bodyLocations) {
      const vm = this;
      return bodyLocations.map((item) => {
        return vm.mapParts[item.loc_body - 1];
      });
    },
    formattedDate: function (dateToformat, flag = false) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat)
          .add(-1 * dayjs(dateToformat).utcOffset(), "minute")
          .format(!flag ? "YYYY-MM-DD hh:mm A" : "MMM-DD hh:mm A");
      } else return "";
    },
    toggleProviderObservation: function () {
      if (
        !(
          this.getCleanedQuestionnaires &&
          this.getCleanedQuestionnaires.length > 0
        )
      ) {
        return;
      }
      const charts = ["painChart", "fatigueChart", "moodChart"];
      charts.forEach((item) => {
        if (
          (this.chartsRef[item].series.w.config.series[1].data.length &&
            this.showProviderObservation) ||
          (!this.chartsRef[item].series.w.config.series[1].data.length &&
            !this.showProviderObservation)
        ) {
          this.chartsRef[item].toggleSeries("Provider Observation");
        }
      });
      this.showProviderObservation = !this.showProviderObservation;
    },
    toggleClientAnswers: function () {
      if (
        !(
          this.getCleanedQuestionnaires &&
          this.getCleanedQuestionnaires.length > 0
        )
      ) {
        return;
      }
      const charts = ["painChart", "fatigueChart", "moodChart"];
      charts.forEach((item) => {
        if (
          (this.chartsRef[item].series.w.config.series[0].data.length &&
            this.showClientAnswers) ||
          (!this.chartsRef[item].series.w.config.series[0].data.length &&
            !this.showClientAnswers)
        ) {
          this.chartsRef[item].toggleSeries("Client Answer");
        }
      });
      this.showClientAnswers = !this.showClientAnswers;
    },
    getActivityCategoriesNames: function (questionnaireActivityCategories) {
      return questionnaireActivityCategories.map((activityCategory) => {
        return activityCategory.name;
      });
    },
    getPercentFor: function (generalSymptomsCategory, cleanedQuestionnaires) {
      const countOfYes = cleanedQuestionnaires.filter(
        (questionnaire) =>
          parseInt(questionnaire[generalSymptomsCategory] || "0") === 1
      ).length;
      return (countOfYes / cleanedQuestionnaires.length) * 100;
    },
  },
};
</script>
<style lang="scss">
.class-ddl {
  .multiselect__tags {
    min-height: 40.5px;
    padding-top: 6px;
  }
}
</style>
<style scoped lang="scss">
#anxietyChart {
  overflow: hidden;
}
.charts {
  .chartContainer {
    margin-top: 32px;
  }
  .dashboard-item-container {
    border-radius: 20px;
    border: 1px solid #c1c4d0;
    padding: 36px 32px;

    @media (max-width: 767px) {
      padding: 32px 12px;
    }
    .dashboard-item-header {
      color: #000000;
      .dashboard-item-title {
        font-size: 28px;
        @media (max-width: 767px) {
          font-size: 22px;
        }
      }
      .see-all {
        font-size: 17px;
        &:hover {
          color: #5cadcd;
        }
        i {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .report-actions-container {
    flex-direction: column-reverse;

    .view-report {
      margin-top: 12px;
    }
  }
}
.print-menu {
  max-height: 350px;
  overflow-y: auto;
  z-index: 1000;
  .dropdown-item.disabled {
    opacity: 0.6;
    &,
    input,
    label {
      pointer-events: none;
      &::before,
      &::after {
        pointer-events: none;
      }
    }
  }
}
.action-indicator {
  width: 12px;
  height: 12px;
  padding: 0;
  position: absolute;
  top: 0px;
  right: 1px;
}
button:disabled {
  pointer-events: none;
}
@media print {
  body {
    background-color: #fff !important;
  }
  .reportTable {
    table-layout: fixed;
  }
  .printPage {
    page-break-after: always;
    margin-top: 120px;
  } /* printPage-break-after works, as well */
}
.multiselect {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
  border-radius: 36px;
}

.border-bottom {
  border-color: var(--secondary-color) !important;
}
.monthly-details {
  margin-top: 16px;

  .info-devider {
    width: 3px;
    border-radius: 1px;
    height: 15px;
  }
  .info-tip {
    padding: 4px 15px;
    font-size: 18px;
  }
  .main-info {
    background-color: var(--theme-color);
    color: var(--main-text-color);
    font-size: 18px;
  }
  .secondary-info {
    background-color: #000000;
    color: #ffffff;
  }
  .info-text {
    font-size: 18px;
  }
  .value {
    font-size: 20px;
    font-weight: 500;
  }
}
</style>
<style lang="scss">
.chart-img {
  svg {
    max-width: 100%;
    width: 300px !important;
    height: 300px !important;
  }
}
.patients-reports-main-container {
  .dashboard-item-container {
    border-radius: 36px !important;
  }
}
.vc-popover-content-wrapper {
  z-index: 100 !important;
}
</style>
